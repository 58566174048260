header {
  height: 40vh;
  padding-top: 0.5rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
  padding: 0.5rem;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.dev-symbol {
  color: var(--color-primary);
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: 'Ubuntu Mono', monospace;
}

.neofetch-window {
  background: var(--color-bg-variant);
  border: 1px solid var(--color-comment);
  border-radius: 6px;
  padding: 2rem;
  min-width: 600px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 2rem;
}

.neofetch-logo {
  color: var(--color-primary);
  font-family: 'Ubuntu Mono', monospace;
  white-space: pre;
  line-height: 1.2;
}

.neofetch-info {
  font-family: 'Ubuntu Mono', monospace;
}

.neofetch-info-line {
  display: flex;
  gap: 1rem;
}

.neofetch-label {
  color: var(--color-primary);
}

.neofetch-value {
  color: var(--color-white);
}

.terminal-window {
  background: var(--color-bg-variant);
  border: 1px solid var(--color-comment);
  border-radius: 6px;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 600px) {
  .terminal-window {
    min-width: unset;
    width: 100%;
    padding: 1rem;
  }
}

.terminal-prompt {
  font-family: 'Ubuntu Mono', monospace;
  margin-bottom: 1rem;
  display: flex;
  text-align: left;
  align-items: center;
}

.prompt-user {
  color: var(--color-green);
}

.prompt-separator, .prompt-colon {
  color: var(--color-white);
}

.prompt-host {
  color: var(--color-purple);
}

.prompt-path {
  color: var(--color-cyan);
}

.prompt-symbol {
  color: var(--color-white);
}

.command {
  color: var(--color-white);
}

.terminal-output {
  font-family: 'Ubuntu Mono', monospace;
  text-align: left;
  display: inline-block;
}

.delayed-output {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  animation-delay: 1.8s;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  50% { border-color: transparent }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  width: 0;
  animation: typing 1.5s steps(20, end) forwards;
}

.output-text {
  display: block;
  margin-bottom: 0.5rem;
}

.output-text .go-string {
  color: var(--color-yellow);
}

.output-result {
  color: var(--color-yellow);
  display: block;
  margin-left: 1rem;
}


/* ==== HEADER SOCIALS ==== */

.header__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.header__socials::before {
  content: '';
  width: 4rem;
  height: 1px;
  background: var(--color-primary);
}

.header__socials::after {
  content: '';
  width: 4rem;
  height: 1px;
  background: var(--color-primary);
}

/* ==== MEDIA QUERIES (MEDIUM DEVICES) ==== */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}
/* ==== MEDIA QUERIES (SMALL DEVICES) ==== */
@media screen and (max-width: 600px) {
  header {
    height: 45vh;
    padding-top: 0;
  }

  .header__container {
    padding: 0.5rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
